import React from 'react'
import Seo from '../components/Seo'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useStaticQuery, graphql } from 'gatsby'
import {
  seo,
  intro,
  main,
  video,
  stages,
  products,
  form,
  buttonBack,
} from '../content/contract-logistics'
import { useLangContext } from '../context/lang.context'
import Main from '../components/Main'
import Video from '../components/Video'
import Stages from '../sections/contract-logistics/Stages'
import Products from '../sections/contract-logistics/Products'
// import IndexIndustries from '../sections/IndexIndustries'
import FormMail from '../components/FormMail'
import ButtonForm from '../components/ButtonForm'

const ContractLogistics = () => {
  const { lang } = useLangContext()

  const { image } = useStaticQuery(graphql`
    {
      image: file(
        absolutePath: { regex: "/images/intros/contract-logistics.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={image.publicURL}
        url={seo.url}
      />
      <Intro data={intro} image={image.childImageSharp} h={2} logo />
      <Main data={main} h={1} />
      <FormMail data={form} template='OmidaLogsitics' />
      <Video data={video} />
      <Products minimal data={products} />
      {/* <IndexIndustries /> */}
      <Stages data={stages} />
      <ButtonForm data={buttonBack} />
    </Layout>
  )
}

export default ContractLogistics
